import type React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	DialogTitle,
} from "@headlessui/react"; // Include Tailwind CSS in your project

function App() {
	const [formData, setFormData] = useState({
		date: "",
		hour: "",
		minute: "",
		number: "",
		aviaCompany: "",
		airportArrival: "",
	});
	const [formValid, setFormValid] = useState(false);
	const [responseLink, setResponseLink] = useState("");
	const [dateError, setDateError] = useState("");
	const [timeError, setTimeError] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (
			!(formData.date && formData.hour && formData.minute) ||
			!(formData.aviaCompany || formData.number || formData.airportArrival)
		) {
			setFormValid(false);
			return;
		}
		setFormValid(true);
	}, [formData]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const today = new Date();
		today.setDate(today.getDate() - 1);
		const selectedDate = new Date(value);
		if (selectedDate < today) {
			setDateError("Дата не может быть меньше текущей.");
		} else {
			setDateError("");
		}
		setFormData((prev) => ({ ...prev, date: value }));
	};

	const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		if (
			(name === "hour" &&
				value.length > 0 &&
				(Number.isNaN(Number.parseInt(value)) ||
					Number.parseInt(value) < 0 ||
					Number.parseInt(value) > 23)) ||
			(name === "minute" &&
				value.length > 0 &&
				(Number.isNaN(Number.parseInt(value)) ||
					Number.parseInt(value) < 0 ||
					Number.parseInt(value) > 59))
		) {
			setTimeError("Время указано неверно. Час: 0-23, минуты: 0-59.");
		} else {
			setTimeError("");
		}
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!formValid) return;

		const { date, hour, minute, aviaCompany, number, airportArrival } =
			formData;

		const parsedDate = new Date(`${date} ${hour}:${minute}`);
		const [year, month, day, hours, minutes] = [
			parsedDate.getFullYear(),
			parsedDate.getMonth() + 1,
			parsedDate.getDate(),
			parsedDate.getHours(),
			parsedDate.getMinutes(),
		];

		const data: Record<string, string> = {
			date_time: `${day}.${month}.${year} ${hours}:${minutes}`,
		};

		if (aviaCompany.length) {
			const isRussian = /[а-яА-ЯЁё]/.test(aviaCompany);
			if (isRussian) {
				data["avia_company"] = aviaCompany;
			} else {
				data["avia_company_en"] = aviaCompany;
			}
		}
		if (number.length) {
			data["number"] = number;
		}
		if (airportArrival.length) {
			data["airport_arrival"] = airportArrival;
		}

		setLoading(true);

		fetch(
			`https://functions.yandexcloud.net/d4e48dopruljnkb1doa0?${new URLSearchParams(data)}`,
		)
			.then((res) => {
				if (res.ok) {
					res.json().then((data) => {
						setResponseLink(data.url);
					});
					setOpenModal(true);
				} else {
					console.error("Request failed", res);
				}
			})
			.catch((err) => {
				console.error("Request error", err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<div className="bg-slate-50 text-gray-900 font-sans">
				<header className="bg-gradient-to-r from-cyan-600 to-sky-300 text-gray-100 p-4 shadow-md">
					<h1 className="p-2 text-4xl font-semibold">
						Уведомления о вылетах из Казанского аэропорта
					</h1>
				</header>

				<main>
					<section className="py-12">
						<div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
							<form
								onSubmit={handleSubmit}
								className="space-y-4 bg-white p-6 shadow-md rounded-lg"
							>
								<div className="mb-8 grid grid-cols-1">
									<div className="inline-flex w-full flex-col justify-start items-start gap-7">
										<div className="w-full flex flex-col justify-start items-start gap-8">
											<div className="w-full justify-start items-start gap-7 flex sm:flex-row flex-col">
												<div className="w-full justify-start items-start gap-1.5 flex flex-col">
													<label
														htmlFor="date"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Дата вылета
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="7"
															height="7"
															viewBox="0 0 7 7"
															fill="none"
															role="status"
															aria-label="Атрибут обязательности"
														>
															<path
																d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
																fill="#EF4444"
															/>
														</svg>
													</label>
													<input
														type="date"
														name="date"
														id="date"
														value={formData.date}
														min={new Date().toJSON().slice(0, 10)}
														onChange={handleDateChange}
														required
														className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
													/>
													{dateError && (
														<p className="mt-2 text-sm text-red-500">
															{dateError}
														</p>
													)}
												</div>
												<div className="w-full justify-start items-start gap-1.5 flex flex-col">
													<label
														htmlFor="hour"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Время
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="7"
															height="7"
															viewBox="0 0 7 7"
															fill="none"
															role="status"
															aria-label="Атрибут обязательности"
														>
															<path
																d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z"
																fill="#EF4444"
															/>
														</svg>
													</label>
													<div className="flex items-center w-lg-1/3">
														<input
															type="text"
															name="hour"
															id="hour"
															value={formData.hour}
															onChange={handleTimeChange}
															min="0"
															max="24"
															maxLength={2}
															placeholder="13"
															className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-l-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
														/>
														<span className="px-2">:</span>
														<label htmlFor="minute" className="hidden">
															Минуты
														</label>
														<input
															type="text"
															name="minute"
															id="minute"
															value={formData.minute}
															onChange={handleTimeChange}
															min="0"
															max="59"
															maxLength={2}
															placeholder="30"
															className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-r-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
														/>
													</div>
													{timeError && (
														<p className="mt-2 text-sm text-red-500">
															{timeError}
														</p>
													)}
												</div>
											</div>
											<div className="w-full justify-start items-start gap-7 flex sm:flex-row flex-col">
												<div className="w-full justify-start items-start gap-1.5 flex flex-col">
													<label
														htmlFor="number"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Номер рейса
													</label>
													<input
														type="text"
														name="number"
														id="number"
														value={formData.number}
														onChange={handleChange}
														className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
													/>
												</div>
												<div className="w-full flex-col justify-start items-start gap-1.5 flex">
													<label
														htmlFor="aviaCompany"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Авиакомпания
													</label>
													<input
														type="text"
														name="aviaCompany"
														id="aviaCompany"
														value={formData.aviaCompany}
														onChange={handleChange}
														className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
													/>
												</div>
											</div>
											<div className="w-full justify-start items-start gap-7 flex sm:flex-row flex-col">
												<div className="w-full flex-col justify-start items-start gap-1.5 flex">
													<label
														htmlFor="airportDeparture"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Аэропорт вылета
													</label>
													<input
														type="text"
														name="airportDeparture"
														id="airportDeparture"
														value="Казань"
														disabled={true}
														className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
													/>
												</div>
												<div className="w-full flex-col justify-start items-start gap-1.5 flex">
													<label
														htmlFor="airportArrival"
														className="flex gap-1 items-center text-gray-600 text-base font-medium leading-relaxed"
													>
														Аэропорт прибытия
													</label>
													<input
														type="text"
														name="airportArrival"
														id="airportArrival"
														value={formData.airportArrival}
														onChange={handleChange}
														className="w-full focus:outline-none text-gray-900 placeholder-gray-400 text-lg font-normal leading-relaxed px-5 py-3 rounded-lg shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] border border-gray-200"
													/>
												</div>
											</div>
										</div>
										<div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
											<p className="text-center text-gray-500 text-base font-normal leading-relaxed">
												Заполните дату и время вылёта, и одно из оставшихся
												полей. Затем перейдите по ссылке в Telegram бот и
												получайте уведомления при изменениях статуса рейса.
											</p>
										</div>
										{loading ? (
											<button
												disabled={true}
												type="button"
												className="mx-auto sm:w-fit w-full px-7 py-3 transition-all duration-700 ease-in-out rounded-xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex bg-sky-600 hover:bg-sky-700 text-gray-100 font-medium"
											>
												<span className="px-2 leading-8">
													<svg
														aria-hidden="true"
														role="status"
														className="inline w-4 h-4 me-3 text-gray-100 animate-spin"
														viewBox="0 0 100 101"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
															fill="#E5E7EB"
														/>
														<path
															d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
															fill="currentColor"
														/>
													</svg>
													Загрузка...
												</span>
											</button>
										) : (
											<button
												type="submit"
												disabled={!formValid}
												className={`mx-auto sm:w-fit w-full px-7 py-3 transition-all duration-700 ease-in-out rounded-xl shadow-[0px_1px_2px_0px_rgba(16,_24,_40,_0.05)] justify-center items-center flex ${
													formValid
														? "bg-sky-600 hover:bg-sky-700"
														: "bg-slate-200"
												}`}
											>
												<span className="px-2 text-center text-gray-100 text-lg font-semibold leading-8">
													Отслеживать
												</span>
											</button>
										)}
									</div>
								</div>
							</form>
						</div>
					</section>
					<section className="pb-16 pt-10 bg-gradient-to-r from-cyan-600 to-sky-300">
						<div className="mx-auto max-w-7xl">
							<div className="mb-12">
								<h2 className="text-4xl text-center font-semibold text-gray-100 mb-4">
									Сколько это стоит?
								</h2>
							</div>
							<div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-8 lg:space-y-0 lg:items-center">
								<div className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12">
									<h3 className="font-sans text-2xl font-bold mb-7 text-center text-sky-600">
										Всегда бесплатно
									</h3>
									<div className="flex items-center justify-center">
										<span className="font-sans text-4xl font-medium text-gray-900">
											0 ₽
										</span>
									</div>
									<p className="text-center text-lg text-gray-600 my-6 group-hover:text-gray-900">
										2 перелёта в год
									</p>
									<p className="py-2.5 px-5 bg-sky-50 shadow-sm rounded-full text-base text-sky-600 font-semibold text-center w-fit mx-auto">
										Уже есть
									</p>
								</div>
								<div className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12">
									<h3 className="font-sans text-2xl font-bold mb-7 text-center text-sky-600">
										Базовый
									</h3>
									<div className="blur flex items-center justify-center font-sans text-4xl font-medium text-gray-900">
										X XXX ₽{/*3 000 ₽*/}
									</div>
									<p className="text-center text-lg text-gray-600 my-6 group-hover:text-gray-900">
										Пакет из 10 перелётов в год
									</p>
									<a
										href="https://t.me/KazanFlightBot/?start=plan=10"
										target="_blank"
										rel="noopener noreferrer"
										className="py-2.5 px-5 bg-sky-50 shadow-sm rounded-full transition-all duration-500 text-base text-sky-600 font-semibold text-center w-fit mx-auto group-hover:bg-sky-600 group-hover:text-gray-100"
									>
										Хочу
									</a>
								</div>
								<div className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12">
									<h3 className="font-sans text-2xl font-bold mb-7 text-center text-sky-600">
										Продвинутый
									</h3>
									<div className="blur flex items-center justify-center font-sans text-4xl font-medium text-gray-900">
										XX XXX ₽{/*30 000 ₽*/}
									</div>
									<p className="text-center text-lg text-gray-600 my-6 group-hover:text-gray-900">
										Пакет из 150 перелётов в год
									</p>
									<a
										href="https://t.me/KazanFlightBot/?start=plan=150"
										target="_blank"
										rel="noopener noreferrer"
										className="py-2.5 px-5 bg-sky-50 shadow-sm rounded-full transition-all duration-500 text-base text-sky-600 font-semibold text-center w-fit mx-auto group-hover:bg-sky-600 group-hover:text-gray-100"
									>
										Хочу
									</a>
								</div>
								{/*<div className="group relative flex flex-col mx-auto w-full max-w-sm bg-white rounded-2xl shadow-2xl transition-all duration-300 p-8 xl:p-12">*/}
								{/*  <h3 className="font-sans text-2xl font-bold mb-7 text-center text-sky-600">Безлимит</h3>*/}
								{/*  <div className="flex items-center justify-center">*/}
								{/*    <span className="font-sans text-4xl font-medium text-gray-900">30 000 ₽</span>*/}
								{/*    <span className="text-xl text-gray-500 ml-3">|&nbsp; Месяц</span>*/}
								{/*  </div>*/}
								{/*  <p className="text-center text-lg text-gray-600 mt-6 group-hover:text-gray-900">Без ограничений перелётов в год</p>*/}
								{/*</div>*/}
							</div>
						</div>
					</section>
				</main>
			</div>
			<Dialog open={openModal} onClose={setOpenModal} className="relative z-10">
				<DialogBackdrop
					transition
					className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
				/>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<DialogPanel
							transition
							className="lg:self-start lg:mt-24 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
						>
							<div className="bg-white px-4 pb-4 pt-2 sm:p-4">
								<div className="grid mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle
										as="div"
										className="font-semibold leading-6 text-gray-900 flex justify-between"
									>
										<h3 className="self-center">Осталось сохранить данные</h3>
										<button
											onClick={() => setOpenModal(false)}
											type="button"
											className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
										>
											<svg
												className="w-3 h-3"
												aria-hidden="true"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 14 14"
											>
												<path
													stroke="currentColor"
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
												/>
											</svg>
											<span className="sr-only">Закрыть</span>
										</button>
									</DialogTitle>
									{responseLink && (
										<div className="mt-4 py-4 place-self-center">
											<a
												href={responseLink}
												target="_blank"
												rel="noopener noreferrer"
												className="justify-center font-medium text-sky-700 bg-sky-50 rounded border p-2.5 transition-all duration-500 hover:bg-sky-100"
											>
												Перейти в Telegram бота
											</a>
										</div>
									)}
								</div>
							</div>
							<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
								<button
									type="button"
									onClick={() => setOpenModal(false)}
									className="mt-3 inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-gray-200 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-sky-500 sm:mt-0 sm:w-auto"
								>
									Закрыть
								</button>
							</div>
						</DialogPanel>
					</div>
				</div>
			</Dialog>
		</div>
	);
}

export default App;
